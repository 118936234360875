import { Settings } from 'react-slick';

export const SLIDER_SETTINGS: Settings = {
  responsive: [
    {
      breakpoint: 99999,
      settings: 'unslick',
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};
